export default {
  title: '角色管理',
  type: 'table',
  key: '/system/role',
  permission: '/system/institutionsRole/list',
  config: {
    dataUrl: '/system/institutionsRole/list',
    filter: {
      controls: [
      ]
    },
    columns: [

      {
        key: 'name',
        width: '180px',
        title: '角色名'
      },

      {
        key: 'description',
        title: '角色描述',
        width: '180px',
        ellipsis: true
      },
      {
        key: 'name',
        title: '操作',
        fixed: 'right',
        type: 'actions',
        width: document.documentElement.clientWidth <= 768 ? '80px' : '180px'
      }
    ],
    actions: [
      {
        key: 'add',
        text: '添加角色',
        icon: 'plus',
        type: 'form',
        permission: '/system/institutionsRole/add',
        config: {
          color: 'primary',
          title: '添加角色',
          submitUrl: '/system/institutionsRole/add',
          submitText: '确认添加',
          controls: [
            {
              key: 'name',
              label: '角色名',
              type: 'text',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 20, message: '只能输入1-20个字符' }
                ]
              }
            },
            {
              key: 'description',
              label: '描述',
              type: 'textarea',
              config: {
                rules: [
                  { min: 1, max: 50, message: '只能输入1-50个字符' }
                ]
              }
            },
          ]
        }
      }
    ],
    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['id'],
      /* 默认排序 */
      defaultSort: '-id'
    },
    /* 表格行操作 */
    rowActions: [
      {
        key: 'edit',
        text: '编辑',
        type: 'form',
        icon: 'edit',
        permission: '/system/institutionsRole/edit',
        config: {
          color: 'primary',
          title: '修改角色',
          initUrl: '/system/institutionsRole/edit/get',
          submitUrl: '/system/institutionsRole/edit',
          submitText: '确认修改',
          controls: [
            {
              key: 'name',
              label: '角色名',
              type: 'text',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 20, message: '只能输入1-20个字符' }
                ]
              }
            },
            {
              key: 'description',
              label: '描述',
              type: 'textarea',
              config: {
                rules: [
                  { min: 1, max: 50, message: '只能输入1-50个字符' }
                ]
              }
            },
          ]
        }
      },
      {
        key: 'assignPermissions',
        text: '分配权限',
        icon: 'lock',
        type: 'form',
        permission: '/system/institutionsRole/assign-permissions',
        config: {
          color: 'primary',
          title: '分配权限',
          initUrl: '/system/institutionsRole/assign-permissions/get',
          submitUrl: '/system/institutionsRole/assign-permissions',
          submitText: '确认分配',
          controls: [
            {
              key: 'permissions',
              label: '权限',
              type: 'tree',
              required: true,
              config: {
                options({ config, isSpecialInstitutions }) {
                  let menus;
                  if (isSpecialInstitutions && isSpecialInstitutions !== '藤德留学') {
                    menus = config.menus.filter(item => item.text !== '审批中心');
                    menus = menus.filter(item => item.text !== '后期服务');
                    // menus.map(item=>{
                    //   item.pages = item.pages.filter(i => i !== '/order/postService');
                    // })
                  } else {
                    menus = config.menus
                  }
                  return menus.map(menu => ({
                    key: menu.key,
                    title: menu.text,
                    scopedSlots: { icon: 'icon' },
                    icon: menu.icon,
                    children: menu.pages.map(pageKey => {
                      const page = config.pages[pageKey];
                      return {
                        key: page.key,
                        title: page.title,
                        icon: page.icon,
                        scopedSlots: { icon: 'icon' },
                        children: [
                          {
                            key: page.permission,
                            title: page.permissionTit ? page.permissionTit : '查看列表'
                          },
                          ...(isSpecialInstitutions == '藤德留学' ? (page.config.actions ?? []) : (page.config.actions.filter(item => !item.isSpecial) ?? [])).map(action => (
                            {
                              title: action.text,
                              icon: action.icon,
                              scopedSlots: { icon: 'icon' },
                              key: action.permission
                            }
                          )),
                          ...(page.config.rowActions ?? []).flatMap(action => (Array.isArray(action) ? action : [action]).map(action => ({
                            title: action.text,
                            icon: action.icon,
                            scopedSlots: { icon: 'icon' },
                            key: action.permission
                          })))
                        ]
                      }
                    })
                  }))
                }
              }
            },
            {}
          ]
        }
      },

    ]
  }
}

