import { intentionLevel, serviceNewItems, customerStatus, gender, serviceItems, hignEducation, languageType, country, sourceChannel, highSeasReasons } from '@/utils/public';
export default {
  title: '事业部员工目标',
  type: 'table',
  key: '/missionGoals/divStaffGoals',
  permission: '/missionGoals/customer/list',
  config: {
    dataUrl: '/institutions/customer/list',
    filter: {
      initUrl: '/institutions/customer/list/get',
      controls: [
        {
          key: 'createdDate',
          label: '创建时间',
          type: 'dateRange'
        },
        {
          key: 'nameContains',
          label: '客户姓名',
          type: 'text'
        },
        {
          key: 'phoneNumberContains',
          label: '手机号',
          type: 'text',
          config: {
            rules: 'phoneNumber'
          }
        },
        {
          key: 'wechatContains',
          label: '微信号',
          type: 'text'
        },
        {
          key: 'intentionLevelIn',
          label: '意向等级',
          type: 'mSelect',
          width: '200',
          config: {
            options: intentionLevel
          }
        },
        {
          key: 'sourceChannelIn',
          label: '来源渠道',
          type: 'mSelect',
          width: '200',
          config: {
            options: sourceChannel
          }
        },
        {
          key: 'userLabelContains',
          label: '客户标签',
          type: 'select',
          config: {
            options: "labelList",
            textKey: 'content',
          }
        },
        {
          key: 'attributionIdIs',
          label: '归属人',
          type: 'select',
          config: {
            options: 'attributablePersonList'
          }
        },
        {
          key: 'followUpWithPeopleIdIs',
          label: '跟进人',
          type: 'select',
          config: {
            options: 'followUpPeopleList'
          }
        },
        {
          key: 'customerStatusIn',
          label: '客户状态',
          type: 'mSelect',
          config: {
            options: customerStatus
          }
        },
      ]
    },
    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['createdDate'],
      /* 默认排序 */
      defaultSort: '-createdDate'
    },
    checkbox: true,
    actions: [
      {
        key: 'add',
        text: '添加客户',
        icon: 'plus',
        type: 'form',
        permission: '/institutions/customer/add',
        config: {
          color: 'primary',
          title: '添加客户',
          initUrl: "/institutions/customer/add/get",
          submitUrl: "/institutions/customer/add",
          submitText: '确认添加',
          submitSuccessMessage: (submitSuccessMessage) => {
            if (submitSuccessMessage.response.message) {
              return `${submitSuccessMessage.response.message}`
            } else {
              return '添加客户成功'
            }

          },
          controls: [
            {
              key: 'name',
              label: '客户姓名',
              type: 'text',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 30, message: '只能输入1-30个字符' }
                ]
              }
            },
            {
              key: 'sourceChannel',
              label: '来源渠道',
              required: true,
              type: 'select',
              config: {
                options: sourceChannel
              }
            },
            {
              key: 'wechat',
              label: '微信号',
              type: 'text',
              required: false,
              config: {
                rules: ['wechat']
              },
              blur: true,
              blurconfig: {
                title: '提示',
                submitUrl: '/institutions/customer/wechat',
                key: "wechat",
                blurMessage: (res) => {
                  return `客户与  ${res.entryPerson} 添加的 ${res.institutionsCustomer}  重复！ 当前客资归属人 ${res.attributablePerson} ， 
                  客户阶段为 ${{
                      TO_BE_FOLLOWED_UP: "待跟进",
                      IN_FOLLOW_UP: "跟进中",
                      HAS_BECOME_A_SINGLE: "已成单",
                      MULTIPLE_ORDERS: "多次成单",
                      ACCURATE_CUSTOMER_FUNDING: '精准客资'
                    }
                    }[res.customerPhase]}，创建时间：${res.createdDate ? res.createdDate.split('T')[0] : ''}`
                },
              },
            },
            {
              key: 'phoneNumber',
              label: '手机号',
              type: 'text',
              required: false,
              config: {
                rules: ['phoneNumber']
              },
              blur: true,
              blurconfig: {
                title: '提示',
                submitUrl: '/institutions/customer/phoneNumber',
                key: "phoneNumber",
                blurMessage: (res) => {
                  return `客户与  ${res.entryPerson} 添加的 ${res.institutionsCustomer}  重复！ 当前客资归属人 ${res.attributablePerson} ， 
                  客户状态为 ${{
                      TO_BE_FOLLOWED_UP: "待跟进",
                      IN_FOLLOW_UP: "跟进中",
                      HAS_BECOME_A_SINGLE: "已成单",
                      MULTIPLE_ORDERS: "多次成单",
                      ACCURATE_CUSTOMER_FUNDING: '精准客资'
                    }[res.customerPhase]}，创建时间：${res.createdDate ? res.createdDate.split('T')[0] : ''}`
                },
              },
            },
            {
              key: 'consultingProject',
              label: '咨询项目',
              dealData: true,
              limitNum: 3,
              type: 'mSelect',
              required: true,
              config: {
                options: serviceNewItems
              }
            },
            {
              key: 'intentionLevel',
              label: '意向等级',
              required: true,
              type: 'select',
              config: {
                options: intentionLevel
              }
            },
            {
              key: 'intendedCountries',
              dealData: true,
              limitNum: 3,
              label: '意向国家',
              type: 'mSelect',
              config: {
                options: country
              }
            },
            {
              key: 'budget',
              label: '预算(人民币)',
              type: 'numeric',
              config: {
                format: "money"
              }
            },
            {
              key: 'gender',
              label: '性别',
              required: false,
              type: 'select',
              config: {
                options: gender
              }
            },
            {
              key: 'residentCity',
              label: '常驻城市',
              type: 'text',
            },
            {
              key: 'highestDegree',
              label: '最高学历',
              type: 'select',
              config: {
                options: hignEducation
              }
            },
            {
              key: 'college',
              label: '就读院校',
              type: 'text',
            },
            {
              key: 'studyProfessional',
              label: '就读专业',
              type: 'text',
            },
            {
              key: 'gpa',
              label: 'GPA',
              type: 'text',
            },
            {
              key: 'languageGradeType',
              label: '语言成绩类型',
              type: 'select',
              config: {
                options: languageType
              }

            },
            {
              key: 'languageAchievement',
              label: '语言成绩',
              type: 'text',
            },
            {
              key: 'graduationTime',
              label: '毕业时间',
              type: 'date',
            },
            {
              key: 'userLabel',
              label: '客户标签',
              dealData: true,
              limitNum: 3,
              type: 'mSelect',
              config: {
                textKey: 'content',
                options: "labelList",
              }
            },
            {
              key: 'remarks',
              label: '其他信息',
              type: 'textarea',
            },
            {
              key: 'title',
              label: '跟进人分配',
              type: 'title',
            },
            {
              key: 'allocationType',
              label: '跟进人分配方式',
              type: 'radio',
              config: {
                options: [
                  {
                    text: '自动分配',
                    value: 'AUTOMATIC',
                    noshow: ({ source }) => {
                      return source == 'MANUAL';
                    }
                  },
                  {
                    text: '手动分配',
                    value: 'MANUAL',
                  },
                ]
              }
            },
            {
              key: 'selectedDepartmentId',
              label: '分配部门',
              type: 'select',
              required: true,
              config: {
                options: "selectedDepartmentId"
              },

              watch: {
                target: 'allocationType',
                sourceKey: ['selectedDepartmentId', 'employees'],
                disabled({ form, sourceValue }) {
                  //自动分配 展示分配部门
                  //第三种情况按员工 隐藏分配部门
                  if (form.allocationType == 'AUTOMATIC' && sourceValue.selectedDepartmentId !== undefined) {
                    return true;
                  } else {
                    return false;
                  }
                }
              }
            },
          ]
        }
      },
      {
        key: 'edit',
        text: '设置归属人',
        icon: 'edit',
        type: 'form',
        isSelected: true,
        permission: '/institutions/customer/setAttributablePerson',
        config: {
          color: 'primary',
          title: '设置归属人',
          info: (rows) => {
            return `您共选择 ${rows.length} 条客资，请选择归属人`
          },
          initUrl: '/institutions/customer/setAttributablePerson/get',
          submitUrl: '/institutions/customer/setAttributablePerson',
          submitText: '确定',
          controls: [
            {
              key: 'attributablePersonId',
              label: '设置归属人',
              type: 'select',
              required: true,
              config: {
                options: 'attributablePersonList'
              }
            },
            {}
          ]
        }
      },
      {
        key: 'edit',
        text: '设置跟进人',
        icon: 'edit',
        type: 'form',
        isSelected: true,
        permission: '/institutions/customer/setFollowUpPeople',
        config: {
          color: 'primary',
          title: '设置跟进人',
          info: (rows) => {
            return `您共选择 ${rows.length} 条客资，请选择跟进人`
          },
          initUrl: '/institutions/customer/setFollowUpPeople/get',
          submitUrl: '/institutions/customer/setFollowUpPeople',
          submitText: '确定',
          controls: [
            {
              key: 'followUpPeopleId',
              label: '设置跟进人',
              type: 'select',
              required: true,
              config: {
                options: 'followUpPeopleIdList'
              }
            },
            {}
          ]
        }
      },
      {
        key: 'edit',
        text: '移入公海',
        icon: 'edit',
        type: 'form',
        isSelected: true,
        permission: '/institutions/customer/highSeas',
        config: {
          color: 'primary',
          title: '移入公海',
          info: (rows) => {
            return `您共选择 ${rows.length} 条客资，是否全部移入公海中`
          },
          // initUrl: '/institutions/customer/setFollowUpPeople/get',
          submitUrl: '/institutions/customer/highSeas',
          submitText: '确定',
          controls: [
            {
              key: 'moveInReason',
              label: '移入原因',
              type: 'select',
              required: true,
              config: {
                options: highSeasReasons
              }
            },
            {}
          ]
        }
      }
    ],
    columns: [
      {
        key: 'createdDate',
        title: '创建时间',
        width: 150,
        type: 'datetime'
      },
      {
        key: 'name',
        title: '客户姓名',
        width: 150,
      },
      {
        key: 'phoneNumber',
        title: '手机号',
        width: 150,
      },
      {
        key: 'wechat',
        title: '微信号',
        width: 150,
      },
      {
        key: 'intentionLevel',
        title: '意向等级',
        width: 150,
        type: 'enum',
        config: {
          constants: {
            HIGH: {
              text: '高',
              color: 'orange'
            },
            MEDIUM: {
              text: '中',
              color: 'green'
            },
            LOW: {
              text: '低',
              color: 'red'
            },
            INVALID: {
              text: '无效',
              color: 'grey'
            },
            null: {
              text: '无',
              color: 'grey'
            },
          }
        }
      },
      {
        key: 'sourceChannel',
        title: '来源渠道',
        width: 150,
      },
      {
        key: 'userLabelList',
        title: '客户标签',
        width: 280,
        type: 'color',
        config: {
          textkey: 'content',
          colorkey: 'color'
        }
      },
      {
        key: 'attribution.name',
        title: '归属人',
        width: 150,
      },

      {
        key: 'followUpWithPeople.name',
        title: '跟进人',
        width: 150,
      },
      {
        key: 'customerStatus',
        title: '客户状态',
        width: 150,
        type: 'enum',
        config: {
          constants: {
            TO_BE_FOLLOWED_UP: {
              text: '待跟进',
              color: 'orange'
            },
            IN_FOLLOW_UP: {
              text: '跟进中',
              color: 'green'
            },
            HAS_BECOME_A_SINGLE: {
              text: '已成单',
              color: 'cyan'
            },
            MULTIPLE_ORDERS: {
              text: '多次成单',
              color: 'blue'
            },
            ACCURATE_CUSTOMER_FUNDING: {
              text: '精准客资',
              color: 'red'
            }
          }
        }
      },
      {
        key: 'numberOfFollowUps',
        title: '跟进次数',
        width: 150,
      },
      {
        key: 'institutionsFollowUp.followUpContent',
        title: '最近跟进情况',
        ellipsis: true,
        width: 150,
      },
      {
        key: 'institutionsFollowUp.nextFollowUpTime',
        title: '下次跟进时间',
        type: 'datetime',
        width: 150,
      },
      {
        key: 'remarks',
        title: '其他信息',
        ellipsis: true,
        width: 150,
      },
      {
        key: 'name',
        title: '操作',
        type: 'actions',
        fixed: 'right',
        width: '300px'
      }
    ],
    rowActions: [

      {
        key: 'edit',
        text: '编辑',
        icon: 'edit',
        type: 'form',
        permission: '/institutions/customer/edit',
        config: {
          color: 'primary',
          title: '编辑客户信息',
          initUrl: "/institutions/customer/edit/get",
          submitUrl: "/institutions/customer/edit",
          submitText: '确认修改',
          submitSuccessMessage: (submitSuccessMessage) => {
            if (submitSuccessMessage.response.message) {
              return `${submitSuccessMessage.response.message}`
            } else {
              return '编辑客户信息成功'
            }

          },
          controls: [
            {
              key: 'name',
              label: '客户姓名',
              type: 'text',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 30, message: '只能输入1-30个字符' }
                ]
              }
            },
            {
              key: 'sourceChannel',
              label: '来源渠道',
              required: true,
              type: 'select',
              isDisabled: (row, key) => {
                if (key === '留服搜索') return true
              },
              config: {
                options: sourceChannel
              }
            },
            {
              key: 'wechat',
              label: '微信号',
              type: 'text',
              required: false,
              config: {
                rules: ['wechat']
              },
            },
            {
              key: 'phoneNumber',
              label: '手机号',
              type: 'text',
              required: false,
              config: {
                rules: ['phoneNumber']
              }
            },
            {
              key: 'consultingProject',
              label: '咨询项目',
              dealData: true,
              limitNum: 3,
              type: 'mSelect',
              required: true,
              config: {
                options: serviceNewItems
              }
            },
            {
              key: 'intentionLevel',
              label: '意向等级',
              required: true,
              type: 'select',
              config: {
                options: intentionLevel
              }
            },
            {
              key: 'intendedCountries',
              dealData: true,
              limitNum: 3,
              label: '意向国家',
              type: 'mSelect',
              config: {
                options: country
              }
            },
            {
              key: 'budget',
              label: '预算(人民币)',
              type: 'numeric',
              config: {
                format: "money"
              }
            },
            {
              key: 'gender',
              label: '性别',
              required: false,
              type: 'select',
              config: {
                options: gender
              }
            },
            {
              key: 'residentCity',
              label: '常驻城市',
              type: 'text',
            },
            {
              key: 'highestDegree',
              label: '最高学历',
              type: 'select',
              config: {
                options: hignEducation
              }
            },
            {
              key: 'college',
              label: '就读院校',
              type: 'text',
            },
            {
              key: 'studyProfessional',
              label: '就读专业',
              type: 'text',
            },
            {
              key: 'gpa',
              label: 'GPA',
              type: 'text',
            },
            {
              key: 'languageGradeType',
              label: '语言成绩类型',
              type: 'select',
              config: {
                options: languageType
              }

            },
            {
              key: 'languageAchievement',
              label: '语言成绩',
              type: 'text',
            },
            {
              key: 'graduationTime',
              label: '毕业时间',
              type: 'date',
            },
            {
              key: 'userLabel',
              label: '客户标签',
              dealData: true,
              limitNum: 3,
              type: 'mSelect',
              config: {
                textKey: 'content',
                options: "labelList",
              }
            },
            {
              key: 'remarks',
              label: '其他信息',
              type: 'textarea',
            },
            {
              key: 'title',
              label: '跟进人分配',
              type: 'title',
              config: {
                hidden({ selectedRows }) {
                  if (selectedRows[0].followUpWithPeople && selectedRows[0].followUpWithPeople.name) {
                    return true;
                  } else {
                    return false;
                  }
                },
              }
            },
            {
              key: 'allocationType',
              label: '跟进人分配方式',
              type: 'radio',
              config: {
                hidden({ selectedRows }) {
                  if (selectedRows[0].followUpWithPeople && selectedRows[0].followUpWithPeople.name) {
                    return true;
                  } else {
                    return false;
                  }
                },
                options: [
                  {
                    text: '自动分配',
                    value: 'AUTOMATIC',
                    noshow: ({ source }) => {
                      return source == 'MANUAL';
                    }
                  },
                  {
                    text: '手动分配',
                    value: 'MANUAL',
                  },
                ]
              }
            },
            {
              key: 'selectedDepartmentId',
              label: '分配部门',
              type: 'select',
              required: true,
              config: {
                options: "selectedDepartmentId",
                hidden({ selectedRows, form, initData }) {
                  if (selectedRows[0].followUpWithPeople.name == undefined && form.allocationType == 'AUTOMATIC' && initData.selectedDepartmentId !== null) {
                    return false;
                  } else {
                    return true;
                  }
                },
              },
              watch: {
                target: 'allocationType',
                sourceKey: ['selectedDepartmentId', 'employees'],
                disabled({ form, sourceValue }) {
                  //自动分配 展示分配部门
                  //第三种情况按员工 隐藏分配部门
                  if (form.allocationType == 'AUTOMATIC' && sourceValue.selectedDepartmentId !== undefined) {
                    return true;
                  } else {
                    return false;
                  }
                }
              }
            },
          ]
        }
      },
      {
        key: 'add',
        text: '跟进',
        icon: 'plus',
        type: 'form',
        permission: '/institutions/customer/addFollowUp',
        config: {
          color: 'primary',
          title: '新增跟进记录',
          initUrl: '/institutions/customer/addFollowUp/get',
          submitUrl: '/institutions/customer/addFollowUp',
          submitText: '确认添加',
          controls: [
            // {
            //   key: 'name',
            //   label: '客户姓名',
            //   disabled: true,
            //   type: 'compact',
            //   required: true,
            //   config: {
            //     key: 'opportunityRecordId',
            //     label: '商机名称',
            //     type: 'select',
            //     config: {
            //       options: "opportunityRecordList",
            //       textKey: 'opportunityName'
            //     }
            //   },
            // },
            {
              key: 'name',
              label: '客户姓名',
              type: 'text',
              disabled: true,
              required: true,
            },
            {
              key: 'opportunityRecordId',
              label: '商机名称',
              type: 'select',
              config: {
                options: "opportunityRecordList",
                textKey: 'opportunityName'
              }
            },
            {
              key: 'followUpTime',
              label: '跟进时间',
              type: 'date',
              disabledDate: true,
              showNow: true,
              required: true,
            },
            {
              key: 'nextFollowUpTime',
              label: '下次跟进时间',
              type: 'date',
              showNow: true,
              required: false,
              config: {
              }
            },
            {
              key: 'intentionLevel',
              label: '意向等级',
              required: true,
              type: 'select',
              config: {
                options: intentionLevel
              }
            },
            {
              key: 'followUpContent',
              label: '跟进内容',
              type: 'textarea',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 500, message: '只能输入1-500个字符' }
                ]
              }
            },
          ]
        }
      },
      {
        key: 'add',
        text: '新建商机',
        icon: 'plus',
        type: 'form',
        permission: '/institutions/customer/newOpportunity',
        config: {
          color: 'primary',
          title: '新建商机',
          initUrl: '/institutions/customer/newOpportunity/get',
          submitUrl: '/institutions/customer/newOpportunity',
          submitText: '确认添加',
          controls: [
            {
              key: 'opportunityStageId',
              label: '',
              type: 'step',
              config: {
                options: "opportunityStageList"
              }
            },
            {
              key: 'opportunityName',
              label: '商机名称',
              type: 'text',
              config: {
                rules: [
                  { min: 1, max: 10, message: '只能输入1-10个字符' }
                ]
              }
            },
            {
              key: 'name',
              label: '相关客户',
              type: 'text',
              disabled: true,
            },
            {
              key: 'businessOpportunityProjectId',
              label: '商机项目',
              type: 'select',
              config: {
                options: "businessOpportunityProjectList",
                textKey: 'content'
              }
            },
            {
              key: 'estimatedSigningAmount',
              label: '预计签单额',
              type: 'numeric',
              config: {
                format: 'rmb',
              }
            }
          ]
        }
      },
      {
        key: 'add',
        text: '成单',
        icon: 'plus',
        type: 'form',
        permission: '/institutions/customer/addOrder',
        config: {
          color: 'primary',
          title: '新增订单',
          initUrl: '/institutions/customer/addOrder/get',
          submitUrl: '/institutions/customer/addOrder',
          submitText: '确认添加',
          controls: [
            {
              key: 'name',
              label: '客户姓名',
              type: 'text',
              disabled: true,
            },
            {
              key: 'signingTime',
              label: '签单时间',
              type: 'date',
              disabledDate: true,
              showNow: true,
              required: true,
            },
            {
              key: 'serviceItem',
              label: '服务项目',
              type: 'select',
              required: true,
              config: {
                options: serviceItems
              }
            },
            {
              key: 'orderAmount',
              label: '订单金额',
              type: 'numeric',
              required: true,
              config: {
                format: 'currency',
                max: (form) => { }
              }
            },
            {
              key: 'attributionId',
              label: '订单归属人',
              type: 'select',
              required: true,
              config: {
                options: 'attributablePersonList'
              }
            },
            {
              key: 'followUpWithPeopleId',
              label: '订单跟进人',
              type: 'select',
              required: false,
              authRequired: {
                institution: '藤德留学',
                type: true
              },
              config: {
                options: 'followUpPeopleIdList'
              }
            },
            {
              key: 'orderStatus',
              label: '订单情况',
              type: 'textarea',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 500, message: '只能输入1-500个字符' }
                ]
              }
            },
          ]
        }
      },
    ]
  }
}
