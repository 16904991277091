import { serviceItems, orderInProgress, reasonCancel, productType } from '@/utils/public';
export default {
  key: '/order/order',
  title: '进行的订单',
  type: 'table',
  permission: '/institutions/institutionsOrder/list',
  config: {
    dataUrl: '/institutions/institutionsOrder/list',
    filter: {
      initUrl: "/institutions/institutionsOrder/list/get",
      controls: [
        {
          key: 'signingTime',
          label: '签单时间',
          type: 'dateRange'
        },
        {
          key: 'nameContains',
          label: '客户姓名',
          type: 'text'
        },
        {
          key: 'serviceItemIdIs',
          label: '服务项目',
          type: 'select',
          config: {
            textKey: 'content',
            options: "signingItemList",
          }
        },
        {
          key: 'paymentStatusIs',
          label: '支付状态',
          type: 'select',
          config: {
            options: orderInProgress
          }
        },
        {
          key: 'attributableIdIs',
          label: '订单归属人',
          type: 'select',
          config: {
            options: 'attributablePersonList'
          }
        },
        {
          key: 'followUpIdIs',
          label: '订单跟进人',
          type: 'select',
          config: {
            options: 'followUpPeopleList'
          }
        },
      ]
    },

    /* 表格操作 */
    actions: [
      {
        key: 'chapter',
        text: '导出数据',
        type: 'url',
        permission: '/institutions/institutionsOrder/export',
        config: {
          title: '导出数据',
          color: 'primary',
        },
        options: {
          url: '/api/institutions/institutionsOrder/export',
          params: true,
          newPage: true
        }
      },
    ],

    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['signingTime'],
      /* 默认排序 */
      defaultSort: '-signingTime'
    },
    columns: [
      {
        key: 'orderNumber',
        title: '订单号',
        width: '150px'
      },
      {
        key: 'signingTime',
        title: '签单时间',
        type: 'datetime',
        width: 150,
      },
      {
        key: 'institutionsCustomerRelationship.name',
        title: '客户姓名',
        width: 150,
      },
      {
        key: 'serviceItem.content',
        title: '服务项目',
        width: 150,
      },
      {
        key: 'orderAmount',
        title: '订单金额',
        width: 150,
        type: 'numeric',
        config: {
          format: 'currency'
        }
      },
      {
        key: 'amountPaid',
        title: '已付金额',
        width: 150,
        type: 'numeric',
        config: {
          format: 'currency'
        }
      },
      {
        key: 'paymentStatus',
        title: '支付状态',
        width: 150,
        type: 'enum',
        config: {
          constants: {
            TO_BE_PAID: {
              text: '待支付',
              color: 'orange'
            },
            IN_PAYMENT: {
              text: '支付中',
              color: 'green'
            },
            PAYMENT_COMPLETED: {
              text: '支付完成',
              color: 'cyan'
            },
            REFUND_IN_PROCESS: {
              text: '退款中',
              color: 'red'
            },
            REFUND_COMPLETE: {
              text: '退款完成',
              color: 'gray'
            },
          }
        }
      },
      {
        key: 'paymentCompletionTime',
        title: '付款完成时间',
        type: 'datetime',
        width: 150,
      },
      {
        key: 'attributable.name',
        title: '订单归属人',
        width: 150,
      },
      {
        key: 'followUp.name',
        title: '订单跟进人',
        width: 150,
      },
      {
        key: 'orderStatus',
        title: '订单情况',
        width: 150,
        ellipsis: true
      },
      {
        key: 'actions',
        title: '操作',
        type: 'actions',
        fixed: 'right',
        width: document.documentElement.clientWidth <= 768 ? '100px' : '350px'
      },
    ],

    /* 表格行操作 */
    rowActions: [
      {
        key: 'detail',
        text: '订单详情',
        type: 'blank',
        permission: '/institutions/institutionsOrder/details',
        config: {
          title: '订单详情',
          component: () => import("./views/orderDetail")
        }
      },
      {
        key: 'edit',
        text: '修改订单金额',
        icon: 'edit',
        type: 'form',
        permission: '/institutions/institutionsOrder/editOrderAmount',
        config: {
          color: 'primary',
          title: '修改订单金额',
          initUrl: "/institutions/institutionsOrder/edit/get",
          submitUrl: "/institutions/institutionsOrder/editOrderAmount",
          submitText: '确认修改',
          controls: [
            {
              key: 'orderAmount',
              label: '订单金额',
              type: 'numeric',
              config: {
                format: 'currency',
                min: 0.01,
                // rules: [
                //   { min: 1, max: 30, message: '只能输入1-30个字符' }
                // ]
              }
            },
            {}
          ]
        }
      },
      {
        key: 'edit',
        text: '编辑',
        icon: 'edit',
        type: 'form',
        permission: '/institutions/institutionsOrder/edit',
        config: {
          color: 'primary',
          title: '编辑订单情况',
          initUrl: "/institutions/institutionsOrder/edit/get",
          submitUrl: "/institutions/institutionsOrder/edit",
          submitText: '确认修改',
          controls: [
            // {
            //   key: 'star',
            //   label: '产品类型',
            //   required: false,
            //   type: 'select',
            //   config: {
            //     options: productType
            //   }
            // },
            {
              key: 'orderStatus',
              label: '订单情况',
              type: 'textarea',
              config: {
                // rules: [
                //   { min: 1, max: 30, message: '只能输入1-30个字符' }
                // ]
              }
            },
            {}
          ]
        }
      },
      {
        key: 'add',
        text: '付款',
        icon: 'plus',
        type: 'form',
        permission: '/institutions/institutionsOrder/payment',
        config: {
          color: 'primary',
          title: '付款',
          initUrl: '/institutions/institutionsOrder/payment/get',
          submitUrl: '/institutions/institutionsOrder/payment',
          submitText: '确认',
          controls: [
            {
              key: 'name',
              label: '客户姓名',
              type: 'text',
              disabled: true,
            },
            {
              key: 'serviceItemId',
              label: '服务项目',
              isDisabled: (rows, form) => {
                return true
              },
              type: 'select',
              config: {
                textKey: 'content',
                options: "signingItemList",
              }
            },
            {
              key: 'orderAmount',
              label: '订单金额',
              type: 'numeric',
              config: {
                format: 'currency'
              },
              disabled: true,
            },
            {
              key: 'amountPaid',
              label: '已付金额',
              type: 'numeric',
              config: {
                format: 'currency'
              },
              disabled: true,
            },
            {
              key: 'amount',
              label: '本次支付',
              type: 'numeric',
              required: true,
              config: {
                format: 'currency',
                max: (form) => {
                  let max = form.orderAmount - form.amountPaid;
                  return max
                }
              },
            },
            {
              key: 'time',
              label: '支付日期',
              type: 'date',
              disabledDate: true,
              showNow: true,
              required: true,
            },
            {
              key: 'paymentVoucher',
              label: '支付凭证',
              required: true,
              tips: "支付凭证的时间和金额要与金额和时间保持一致",
              type: 'file',
              config: {
                hidden({ institutionsName }) {
                  if (institutionsName == '藤德留学') {
                    return false;
                  } else {
                    return true;
                  }
                },
                uploadUrl: '/api/institutions/institutionsOrder/addUpload',
                format: "image",
                accept: ["jpeg", "png", 'jpg']
              }
            }, {}
          ]
        },
        disabled: ({ selectedRows }) => {
          return selectedRows[0].paymentStatus === "PAYMENT_COMPLETED";
        },
      },
      {
        key: 'add',
        text: '取消订单',
        icon: 'plus',
        type: 'form',
        permission: '/institutions/institutionsOrder/cancelOrder',
        config: {
          color: 'primary',
          title: '取消订单',
          initUrl: '/institutions/institutionsOrder/cancelOrder/get',
          submitUrl: '/institutions/institutionsOrder/cancelOrder',
          submitText: '确认',
          controls: [
            {
              key: 'name',
              label: '客户姓名',
              type: 'text',
              disabled: true,
            },
            {
              key: 'serviceItemId',
              label: '服务项目',
              isDisabled: (rows, form) => {
                return true
              },
              type: 'select',
              config: {
                textKey: 'content',
                options: "signingItemList",
              }
            },
            {
              key: 'orderAmount',
              label: '订单金额',
              type: 'numeric',
              config: {
                format: 'currency'
              },
              disabled: true,
            },
            {
              key: 'amountPaid',
              label: '已付金额',
              type: 'numeric',
              config: {
                format: 'currency'
              },
              disabled: true,
            },
            {
              key: 'reasonForCancellation',
              label: '取消原因',
              type: 'select',
              required: true,
              config: {
                options: reasonCancel
              }
            },
            {
              key: 'amountToBeRefunded',
              label: '应退金额',
              type: 'numeric',
              required: true,
              tips: '需要退款的订单,在取消的订单列表中操作退款',
              config: {
                format: 'currency',
                max: (form) => {
                  return form.amountPaid
                }
              }
            },
            {
              key: 'cancelTime',
              label: '取消时间',
              type: 'date',
              showNow: true,
              disabledDate: true,
              required: true,
            },
            {
              key: 'orderStatus',
              label: '订单情况',
              type: 'textarea',
            },
          ]
        }
      },
      // {
      //   key: 'add',
      //   text: '退款',
      //   icon: 'plus',
      //   type: 'form',
      //   permission: '/institutions/institutionsOrder/refund',
      //   config: {
      //     color: 'primary',
      //     title: '退款',
      //     initUrl: '/institutions/institutionsOrder/refund/get',
      //     submitUrl: '/institutions/institutionsOrder/refund',
      //     submitText: '确认',
      //     controls: [
      //       {
      //         key: 'name',
      //         label: '客户姓名',
      //         type: 'text',
      //         disabled: true,
      //       },
      //       {
      //         key: 'serviceItem',
      //         label: '服务项目',
      //         type: 'text',
      //         disabled: true,
      //       },
      //       {
      //         key: 'orderAmount',
      //         label: '订单金额',
      //         type: 'text',
      //         disabled: true,
      //       },
      //       {
      //         key: 'amountPaid',
      //         label: '已付金额',
      //         type: 'text',
      //         disabled: true,
      //       },
      //       {
      //         key: 'amount',
      //         label: '本次退款',
      //         type: 'numeric',
      //         required: true,
      //         config: {
      //           format: 'currency',
      //           max: (form) => {
      //             return form.amountPaid
      //           }
      //         }
      //       },
      //       {
      //         key: 'time',
      //         label: '退款日期',
      //         type: 'datetime',
      //         required: true,
      //       },
      //     ]
      //   }
      // },
    ]
  }
};
