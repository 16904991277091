import dayjs from "dayjs";
export default {
  key: '/fee/settlement',
  title: '提成结算',
  type: 'table',
  permission: '/institutions/commission/settlement/getCommissionSettlementList',
  config: {
    scroll: { x: 260 },
    pagination: false,
    rowSpan: true,
    methods: 'postRequest',
    dataUrl: '/institutions/commission/settlement/getCommissionSettlementList',
    filter: {
      initUrl: "/institutions/commission/settlement/list/get",
      controls: [
        {
          key: 'yearMonth',
          label: '时间',
          type: 'date',
          disabledDate: true,
          noResetFilter: true,
          config: {
            picker: 'month',
            initialValue: '上月',
          }
        },
        {
          key: 'departmentOrEmployee',
          label: '部门/员工',
          type: 'compact',
          noResetFilter: true,
          config: {
            type: 'select',
            initialValue: 'departmentId',
            options: [
              {
                text: '按部门',
                value: 'departmentId',
                url: '/institutions/commission/settlement/list/get',
              },
              {
                text: '按员工',
                value: 'employeeId',
                url: '/institutions/commission/settlement/employeeAll'
              }
            ]
          },
          config2: {
            key: 'departmentId',
            type: 'select',
            options: "departmentList",
            textKey: 'name'
          }
        },
      ]
    },

    /* 表格操作 */
    actions: [
      {
        key: 'edit',
        text: ({ filterForm }) => {
          const current = dayjs(filterForm.yearMonth).format('MM')
          return `重新计算${current}月提成`
        },
        icon: 'plus',
        type: 'dialog',
        disabled: ({ filterForm }) => {
          const current = dayjs(filterForm.yearMonth)
          const twoMonthsAgo = dayjs().subtract(2, 'month');
          return current.isBefore(twoMonthsAgo);
        },
        isSelected: false,  // 是否必勾选
        determine: true,  // 是否需要判定条
        permission: '/institutions/commission/settlement/recalculate',
        needPermission: true,
        showPermission: (e) => {
          if (e !== '藤德留学') return true
        },
        modalObj: (rowData, tableData) => {
          return `重新计算会清空当前已经计算的提成，计算时间需要几分钟到十几分钟不等，请稍后刷新页面查看`
        },
        config: {
          title: '警告',
          color: 'primary',
          methods: 'postRequest',
          isFilter: true,
          submitUrl: '/institutions/commission/settlement/recalculate',
          submitText: '确认',
          controls: []
        }
      },
      {
        key: 'chapter',
        text: '导出数据',
        type: 'url',
        permission: '/institutions/commission/settlement/exportDetail',
        config: {
          title: '导出数据',
          color: 'primary',
        },
        options: {
          url: '/api/institutions/commission/settlement/exportDetail',
          params: true,
          newPage: true
        }
      },
    ],

    /* 表格排序 */
    sorter: {

    },
    columns: [
      {
        key: 'yearAndMonth',
        title: '月份',
        width: document.documentElement.clientWidth <= 768 ? '60px' : '130px',
        fixed: 'left',
        customCell: (_) => {
          return { rowSpan: _.rowSpan };
        },
      },
      {
        key: 'employeeName',
        title: '姓名',
        width: document.documentElement.clientWidth <= 768 ? '60px' : '130px',
        fixed: 'left',
        customCell: (_) => {
          return { rowSpan: _.rowSpan };
        },
      },
      {
        key: 'departmentName',
        title: '部门',
        width: '130px',
        customCell: (_) => {
          return { rowSpan: _.rowSpan };
        },
      },
      // {
      //   key: 'planRuleName',
      //   title: '方案名',
      //   width: '130px',
      //   customCell: (_) => {
      //     return { rowSpan: _.rowSpan };
      //   },
      // },
      {
        key: 'totalAmount',
        title: '业绩',
        type: 'numeric',
        config: {
          format: 'currency'
        },
        width: '130px',
        customCell: (_) => {
          return { rowSpan: _.rowSpan };
        },
      },
      {
        key: 'totalCommissionAmount',
        title: '提成总额',
        type: 'numeric',
        config: {
          format: 'currency'
        },
        width: '130px',
        customCell: (_) => {
          return { rowSpan: _.rowSpan };
        },
      },
      {
        key: 'accountIdentity',
        title: '账号身份',
        width: '130px',
        customCell: (_) => {
          return { rowSpan: _.rowSpan };
        },
      },
      {
        key: 'accountStatus',
        title: '账号状态',
        width: '130px',
        customCell: (_) => {
          return { rowSpan: _.rowSpan };
        },
      },
      {
        title: '提成明细',
        children: [
          {
            title: '订单号',
            dataIndex: 'detailList_orderNo',
            key: 'detailList_orderNo',
            width: 200,
          },
          {
            title: '签单时间',
            dataIndex: 'detailList_signedTime',
            key: 'detailList_signedTime',
            type: 'datetime',
            width: 200,
          },
          {
            title: '客户姓名',
            dataIndex: 'detailList_customerName',
            key: 'detailList_customerName',
            width: 200,
          },
          {
            title: '服务项目',
            dataIndex: 'detailList_serviceItem',
            key: 'detailList_serviceItem',
            width: 200,
          },
          {
            title: '产品类型',
            dataIndex: 'detailList_productType',
            key: 'detailList_productType',
            width: 200,
            type: 'textCustom',
            popover: '标准产品提成会在每次收款的次月结算；非标产品提成会在订单支付完成的次月结算',
            config: {
              text: (val) => {
                return val == 0 ? '标准产品' : '非标产品'
              }
            },
          },
          {
            title: '订单金额',
            dataIndex: 'detailList_orderAmount',
            key: 'detailList_orderAmount',
            type: 'numeric',
            config: {
              format: 'currency'
            },
            width: 200,
          },
          {
            title: '付款时间',
            dataIndex: 'detailList_paymentTime',
            key: 'detailList_paymentTime',
            type: 'datetime',
            width: 200,
          },
          {
            title: '付款金额',
            dataIndex: 'detailList_paymentAmount',
            key: 'detailList_paymentAmount',
            type: 'numeric',
            config: {
              format: 'currency'
            },
            width: 200,
          },
          {
            title: '提成比例',
            dataIndex: 'detailList_rate',
            key: 'detailList_rate',
            width: 200,
            type: 'textCustom',
            config: {
              text: (val) => {
                return (val * 100).toFixed(2) + '%'
              }
            },
          },
          {
            title: '提成分配系数',
            dataIndex: 'detailList_distributionRate',
            key: 'detailList_distributionRate',
            width: 200,
            type: 'textCustom',
            config: {
              text: (val) => {
                return (val * 100).toFixed(2) + '%'
              }
            },
          },
          {
            title: '提成金额',
            dataIndex: 'detailList_commissionAmount',
            key: 'detailList_commissionAmount',
            popover: '付款金额*提成比例*提成分配系数，四舍五入取整',
            type: 'numeric',
            config: {
              format: 'currency'
            },
            width: 200,
          },
          {
            title: '订单归属人',
            dataIndex: 'detailList_attributionName',
            key: 'detailList_attributionName',
            width: 200,
          },
          {
            title: '订单跟进人',
            dataIndex: 'detailList_followUpName',
            key: 'detailList_followUpName',
            width: 200,
          },
        ],
      },
    ],

    /* 表格行操作 */
    rowActions: []
  }
};
