import * as http from "@/utils/http";

let profile;
let profileLoading;
let waitingResolves = [];

export async function getProfile() {
  if (profile != null) { // 已经有加载
    return profile;
  }

  if (profileLoading) { // 如果已经正在加载，进行等待
    return new Promise(resolve => {
      waitingResolves.push(resolve);
    });
  }

  profileLoading = true;
  try {
    const { admin, alert, username, permissions, institutions, online, notReadNumber } = await http.get('/auth/profile')
    profile = {
      admin,
      alert,
      username,
      institutions,
      online,
      permissions,
      notReadNumber,
      everyPermissions(...expressions) {
        return admin || permissions != null && expressions.every(p => permissions.includes(p));
      }
    };
    profileLoading = false;
    waitingResolves.forEach(resolve => resolve(profile));
    waitingResolves = [];
    return profile;
  } catch (data) {
    console.warn(data.message);
    profileLoading = false;
    waitingResolves = [];
    return Promise.reject(data);
  }
}

export function clearProfile() {
  profile = null;
}
